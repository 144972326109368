<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          우측 상단의 <user-avatar/>를 클릭하여 원하시는 계정을 생성하여주세요.
        </h2>
        <p class="message">
          {{ "Project X를 통해 광고를 집행하고 싶으시면 광고계정에,\nProject X에 지면을 판매하고 싶으시면 채널계정에 워크스페이스를 추가해주세요.\n둘 다 원하신다구요? 둘다 만드시면 됩니다!" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { $themeConfig } from '@themeConfig'
import UserAvatar from '@/views/components/app-navbar/UserAvatar.vue'

export default {
  components: {
    UserAvatar,
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/pages/page-misc';
.message {
  text-align: center;
  white-space: pre-wrap;
  margin-top: 80px;
  margin-bottom: 40px;
  line-height: 2rem;
}
.badge-minimal {
  margin-bottom: 20px;
}
</style>
