<template>
  <div class="d-sm-flex d-none user-nav">
    <b-avatar
      id="user-avatar"
      class="ml-1"
      size="40"
      variant="primary"
      button
    >
      {{ userData.name.split('')[0] }}
    </b-avatar>
  </div>
</template>

<script>
import {
  BAvatar,
} from 'bootstrap-vue'
import { inject } from '@vue/composition-api'

export default {
  components: {
    BAvatar,
  },
  setup() {
    const userData = inject('userData')
    return {
      userData,
    }
  },
}
</script>
